// ブログ一覧ページのテンプレート

import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Blog = ({ data, location, pageContext }) => {
  return (
    <>
      <SEO
        pagetitle="相氣一進流のブログ"
        pageDesc="相氣一進流のブログ一覧です。"
      />
      <Layout>
        <section className="blogList">
          <div className="blogListContainer">
            <h1>ブログ一覧</h1>
            <div className="posts">
              {data.allContentfulBlogPost.edges.map(({ node }) => (
                <article className="post" key={node.id}>
                  <Link to={`/blog/${node.slug}`}>
                    <figure>
                      <Img
                        fluid={node.eyecatch.fluid}
                        alt={node.eyecatch.description}
                        style={{ height: "100%" }}
                      />
                    </figure>
                    <h2>{node.title}</h2>
                    <p className="publishDate">{node.publishDateJP}</p>
                  </Link>
                </article>
              ))}
            </div>
          </div>
          <ul className="pageNation">
            {!pageContext.isFirst && (
              <li className="prev">
                <Link
                  to={
                    pageContext.currentPage === 2
                      ? `/bloglist/`
                      : `/bloglist/${pageContext.currentPage - 1}/`
                  }
                  rel="prev"
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                  <span>前のページ</span>
                </Link>
              </li>
            )}
            {!pageContext.isLast && (
              <li className="next">
                <Link
                  to={`/bloglist/${pageContext.currentPage + 1}/`}
                  rel="next"
                >
                  <span>次のページ</span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </Link>
              </li>
            )}
          </ul>
        </section>
      </Layout>
    </>
  );
};

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { fields: publishDate, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          title
          id
          slug
          publishDate
          publishDateJP: publishDate(formatString: "YYYY/M/D")
          eyecatch {
            fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid_withWebp
            }
            description
          }
        }
      }
    }
  }
`;

export default Blog;
